export const personaOptions = [
    {
        title: 'Parent (Mother / Father)',
        value: 'parent',
    },
    {
        title: 'Spouse / Significant Other',
        value: 'spouse',
    },
    {
        title: 'Struggling Myself',
        value: 'myself',
    },
    {
        title: 'Family / Friend / Other',
        value: 'family',
    },
    {
        title: 'A Healthcare Professional',
        value: 'healthcare',
    },
    {
        title: 'Alumni / Relapsing',
        value: 'alumni',
    },
    {
        title: 'A Veteran',
        value: 'veteran',
    },
];
export const personaCtas = [
    {
        title: 'Information about treatment',
        value: 'info',
        ctas: {
            parent: {
                title: 'Getting help for an addicted family member can be difficult. ',
                buttonText: 'Guide for Families',
                href: '/rehab-guide/guide-for-families-ii',
            },
            spouse: {
                title: 'Helping a spouse face their addiction challenges takes a team effort.',
                buttonText: 'How to Help an Addicted Spouse',
                href: '/rehab-guide/married-to-a-drug-addict',
            },
            myself: {
                title: 'Learn more about addiction treatment, the process to recovery, finding a treatment center, and options for affording treatment.',
                buttonText: 'Drug Rehab Guide',
                href: '/rehab-guide',
            },
            family: {
                title: 'Learn more about addiction treatment, the process to recovery, finding a treatment center, and options for affording treatment.',
                buttonText: 'Drug Rehab Guide',
                href: '/rehab-guide',
            },
            healthcare: {
                title: 'Substance abuse impacts many healthcare workers throughout the United States.',
                buttonText: 'Rehab for Healthcare Professionals',
                href: '/healthcare-professionals',
            },
            alumni: {
                title: 'Here are a few helpful tips that can help you avoid a relapse during your recovery.',
                buttonText: 'Tips to Stop Relapsing',
                href: '/rehab-guide/ways-to-avoid-relapse',
            },
            veteran: {
                title: 'American Addiction Centers offers a specialized treatment program designed to help Veterans struggling with substance use and mental health disorders',
                buttonText: 'Veteran Program',
                href: '/veterans/specialized-treatment-program',
            },
        },
    },
    {
        title: 'Cost & insurance information',
        value: 'cost',
        ctas: {
            parent: {
                title: 'We offer the different options – which might be able to be combined – to help you pay for care.',
                buttonText: 'Payment Options',
                href: '/payment-options',
            },
            spouse: {
                title: 'We offer the different options – which might be able to be combined – to help you pay for care.',
                buttonText: 'Payment Options',
                href: '/payment-options',
            },
            myself: {
                title: 'We offer the different options – which might be able to be combined – to help you pay for care.',
                buttonText: 'Payment Options',
                href: '/payment-options',
            },
            family: {
                title: 'We offer the different options – which might be able to be combined – to help you pay for care.',
                buttonText: 'Payment Options',
                href: '/payment-options',
            },
            healthcare: {
                title: 'We offer the different options – which might be able to be combined – to help you pay for care.',
                buttonText: 'Payment Options',
                href: '/payment-options',
            },
            alumni: {
                title: 'We offer the different options – which might be able to be combined – to help you pay for care.',
                buttonText: 'Payment Options',
                href: '/payment-options',
            },
            veteran: {
                title: 'VA insurance includes treatment for substance abuse, mental health, and other co-occurring disorders.',
                buttonText: 'Treatment Through VA Insurance',
                href: '/insurance-coverage/va-benefits',
            },
        },
    },
    {
        title: 'Admissions information',
        value: 'admissions',
        ctas: {
            parent: {
                title: "We've outlined the steps you can expect to undergo during the admissions process for American Addiction Centers' (AAC) treatment facilities.",
                buttonText: 'Rehab Admissions Process',
                href: '/admissions',
            },
            spouse: {
                title: "We've outlined the steps you can expect to undergo during the admissions process for American Addiction Centers' (AAC) treatment facilities.",
                buttonText: 'Rehab Admissions Process',
                href: '/admissions',
            },
            myself: {
                title: "We've outlined the steps you can expect to undergo during the admissions process for American Addiction Centers' (AAC) treatment facilities.",
                buttonText: 'Rehab Admissions Process',
                href: '/admissions',
            },
            family: {
                title: "We've outlined the steps you can expect to undergo during the admissions process for American Addiction Centers' (AAC) treatment facilities.",
                buttonText: 'Rehab Admissions Process',
                href: '/admissions',
            },
            healthcare: {
                title: "We've outlined the steps you can expect to undergo during the admissions process for American Addiction Centers' (AAC) treatment facilities.",
                buttonText: 'Rehab Admissions Process',
                href: '/admissions',
            },
            alumni: {
                title: "We've outlined the steps you can expect to undergo during the admissions process for American Addiction Centers' (AAC) treatment facilities.",
                buttonText: 'Rehab Admissions Process',
                href: '/admissions',
            },
            veteran: {
                title: 'American Addiction Centers offers a specialized treatment program designed to help Veterans struggling with substance use and mental health disorders',
                buttonText: 'Veteran Program',
                href: ' /insurance-coverage/va-benefits',
            },
        },
    },
    {
        title: 'View treatment centers',
        value: 'facilities',
        ctas: {
            parent: {
                title: 'American Addiction Centers provides addiction and mental health care at various locations nationwide.',
                buttonText: 'View our locations',
                href: '/treatment-centers',
            },
            spouse: {
                title: 'American Addiction Centers provides addiction and mental health care at various locations nationwide.',
                buttonText: 'View our locations',
                href: '/treatment-centers',
            },
            myself: {
                title: 'American Addiction Centers provides addiction and mental health care at various locations nationwide.',
                buttonText: 'View our locations',
                href: '/treatment-centers',
            },
            family: {
                title: 'American Addiction Centers provides addiction and mental health care at various locations nationwide.',
                buttonText: 'View our locations',
                href: '/treatment-centers',
            },
            healthcare: {
                title: 'American Addiction Centers provides addiction and mental health care at various locations nationwide.',
                buttonText: 'View our locations',
                href: '/treatment-centers',
            },
            alumni: {
                title: 'American Addiction Centers provides addiction and mental health care at various locations nationwide.',
                buttonText: 'View our locations',
                href: '/treatment-centers',
            },
            veteran: {
                title: 'American Addiction Centers offers a specialized treatment program designed to help Veterans struggling with substance use and mental health disorders',
                buttonText: 'Veteran Program',
                href: '/veterans/specialized-treatment-program',
            },
        },
    },
    {
        title: 'Outpatient addiction treatment',
        value: 'outpatient',
        ctas: {
            parent: {
                title: 'Our outpatient programs offer expert care and support, helping you build a strong foundation for lasting recovery while keeping you connected to your daily life.',
                buttons: [
                    {
                        buttonText: 'What is Outpatient Treatment?',
                        href: '/rehab-guide/outpatient-treatment',
                    },
                    {
                        buttonText: 'View Outpatient Treatment Centers',
                        href: '/treatment-centers/outpatient-rehab',
                    },
                    {
                        buttonText: 'Outpatient Insurance Coverage',
                        href: '/insurance-coverage/outpatient',
                    },
                ],
            },
            spouse: {
                title: 'Our outpatient programs offer expert care and support, helping you build a strong foundation for lasting recovery while keeping you connected to your daily life.',
                buttons: [
                    {
                        buttonText: 'What is Outpatient Treatment?',
                        href: '/rehab-guide/outpatient-treatment',
                    },
                    {
                        buttonText: 'View Outpatient Treatment Centers',
                        href: '/treatment-centers/outpatient-rehab',
                    },
                    {
                        buttonText: 'Outpatient Insurance Coverage',
                        href: '/insurance-coverage/outpatient',
                    },
                ],
            },
            myself: {
                title: 'Our outpatient programs offer expert care and support, helping you build a strong foundation for lasting recovery while keeping you connected to your daily life.',
                buttons: [
                    {
                        buttonText: 'What is Outpatient Treatment?',
                        href: '/rehab-guide/outpatient-treatment',
                    },
                    {
                        buttonText: 'View Outpatient Treatment Centers',
                        href: '/treatment-centers/outpatient-rehab',
                    },
                    {
                        buttonText: 'Outpatient Insurance Coverage',
                        href: '/insurance-coverage/outpatient',
                    },
                ],
            },
            family: {
                title: 'Our outpatient programs offer expert care and support, helping you build a strong foundation for lasting recovery while keeping you connected to your daily life.',
                buttons: [
                    {
                        buttonText: 'What is Outpatient Treatment?',
                        href: '/rehab-guide/outpatient-treatment',
                    },
                    {
                        buttonText: 'View Outpatient Treatment Centers',
                        href: '/treatment-centers/outpatient-rehab',
                    },
                    {
                        buttonText: 'Outpatient Insurance Coverage',
                        href: '/insurance-coverage/outpatient',
                    },
                ],
            },
            healthcare: {
                title: 'Our outpatient programs offer expert care and support, helping you build a strong foundation for lasting recovery while keeping you connected to your daily life.',
                buttons: [
                    {
                        buttonText: 'What is Outpatient Treatment?',
                        href: '/rehab-guide/outpatient-treatment',
                    },
                    {
                        buttonText: 'View Outpatient Treatment Centers',
                        href: '/treatment-centers/outpatient-rehab',
                    },
                    {
                        buttonText: 'Outpatient Insurance Coverage',
                        href: '/insurance-coverage/outpatient',
                    },
                ],
            },
            alumni: {
                title: 'Our outpatient programs offer expert care and support, helping you build a strong foundation for lasting recovery while keeping you connected to your daily life.',
                buttons: [
                    {
                        buttonText: 'What is Outpatient Treatment?',
                        href: '/rehab-guide/outpatient-treatment',
                    },
                    {
                        buttonText: 'View Outpatient Treatment Centers',
                        href: '/treatment-centers/outpatient-rehab',
                    },
                    {
                        buttonText: 'Outpatient Insurance Coverage',
                        href: '/insurance-coverage/outpatient',
                    },
                ],
            },
            veteran: {
                title: 'Our outpatient programs offer expert care and support, helping you build a strong foundation for lasting recovery while keeping you connected to your daily life.',
                buttons: [
                    {
                        buttonText: 'What is Outpatient Treatment?',
                        href: '/rehab-guide/outpatient-treatment',
                    },
                    {
                        buttonText: 'View Outpatient Treatment Centers',
                        href: '/treatment-centers/outpatient-rehab',
                    },
                    {
                        buttonText: 'Outpatient Insurance Coverage',
                        href: '/insurance-coverage/outpatient',
                    },
                ],
            },
        },
    },
    {
        title: 'Refer someone to treatment',
        value: 'refer',
        ctas: {
            parent: {
                title: 'If you or someone you know is struggling with addiction and need a referral to rehab, contact us today.',
                buttonText: 'Treatment Referrals',
                href: '/rehab-referrals',
            },
            spouse: {
                title: 'If you or someone you know is struggling with addiction and need a referral to rehab, contact us today.',
                buttonText: 'Treatment Referrals',
                href: '/rehab-referrals',
            },
            myself: {
                title: 'If you or someone you know is struggling with addiction and need a referral to rehab, contact us today.',
                buttonText: 'Treatment Referrals',
                href: '/rehab-referrals',
            },
            family: {
                title: 'American Addiction Centers provides addiction and mental health care at various locations nationwide.',
                buttonText: 'View our locations',
                href: '/treatment-centers',
            },
            healthcare: {
                title: 'If you or someone you know is struggling with addiction and need a referral to rehab, contact us today.',
                buttonText: 'Treatment Referrals',
                href: '/rehab-referrals',
            },
            alumni: {
                title: 'If you or someone you know is struggling with addiction and need a referral to rehab, contact us today.',
                buttonText: 'Treatment Referrals',
                href: '/rehab-referrals',
            },
            veteran: {
                title: 'American Addiction Centers offers a specialized treatment program designed to help Veterans struggling with substance use and mental health disorders',
                buttonText: 'Veteran Program',
                href: '/veterans/specialized-treatment-program',
            },
        },
    },
    {
        title: 'Not sure',
        value: 'unsure',
        ctas: {
            parent: {
                title: 'Our passionate and dedicated admissions navigator team is just a phone call away from getting you the help you deserve.',
            },
            spouse: {
                title: 'Our passionate and dedicated admissions navigator team is just a phone call away from getting you the help you deserve.',
            },
            myself: {
                title: 'Our passionate and dedicated admissions navigator team is just a phone call away from getting you the help you deserve.',
            },
            family: {
                title: 'Our passionate and dedicated admissions navigator team is just a phone call away from getting you the help you deserve.',
            },
            healthcare: {
                title: 'Our passionate and dedicated admissions navigator team is just a phone call away from getting you the help you deserve.',
            },
            alumni: {
                title: 'Our passionate and dedicated admissions navigator team is just a phone call away from getting you the help you deserve.',
            },
            veteran: {
                title: 'American Addiction Centers has a dedicated and confidential Veteran rehab admissions hotline available toll-free, 24/7.',
            },
        },
    },
];
