import {personaOptions, personaCtas} from './helpers';
import {useForm} from 'react-hook-form';
import Button from 'aac-components/components/Button';
import CallrailButton from '../CallrailButton';
import styled from 'styled-components';

import SelectInput from 'aac-components/components/ReactHookForm/SelectInput';
import {useEffect, useState} from 'react';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';

const PersonaAssessment = ({title = 'Personalize your experience'}) => {
    const form = useForm();
    const [isDisabled, setIsDisabled] = useState(true);
    const [showCta, setShowCta] = useState(false);

    // watch for form updates, we can enable the submit button when both dropdowns have selections
    const {persona_choice, search_choice} = form?.watch();
    useEffect(() => {
        setIsDisabled(!!persona_choice && !!search_choice);
    }, [persona_choice, search_choice]);

    const onSubmit = () => {
        setShowCta(true);
        // heap
        window?.heap &&
            window?.heap?.track('Persona Assessment Submit', {
                persona_choice,
                search_choice,
            });
    };

    const cta = personaCtas.filter((cta) => cta?.value === search_choice)?.[0]?.ctas?.[
        persona_choice
    ];

    let buttonsContent;
    if (cta?.buttons && Array.isArray(cta.buttons)) {
        buttonsContent = cta.buttons;
    }

    return (
        <div className="persona-assessment">
            <div className="header">
                <div dangerouslySetInnerHTML={{__html: title}} />
            </div>
            {showCta ? (
                <div className="container">
                    <div className="back" onClick={() => setShowCta(false)}>
                        <IconChevron fill="var(--interactive)" rotate="90" />
                        Back
                    </div>

                    <div
                        className="cta__title"
                        dangerouslySetInnerHTML={{
                            __html: cta?.title,
                        }}
                    />
                    <div className="cta__button">
                        {buttonsContent ? (
                            buttonsContent.map((button, index) => (
                                <div className="cta__button" key={index}>
                                    {button?.href ? (
                                        <Button
                                            href={button.href}
                                            title={button.buttonText}
                                            style={{
                                                fontWeight: 'normal',
                                                margin:
                                                    index === buttonsContent.length - 1
                                                        ? '0 auto'
                                                        : '24px auto',
                                                display: 'block',
                                                width: '100%',
                                            }}>
                                            {button.buttonText}
                                        </Button>
                                    ) : (
                                        <CallrailButton style={{margin: '0 auto'}} />
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className="cta__button">
                                {cta?.href ? (
                                    <Button
                                        href={cta?.href}
                                        title={cta?.buttonText}
                                        style={{
                                            fontWeight: 'normal',
                                            margin: '0 auto',
                                            display: 'block',
                                            maxWidth: 'fit-content',
                                        }}>
                                        {cta?.buttonText}
                                    </Button>
                                ) : (
                                    <CallrailButton style={{margin: '0 auto'}} />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="container">
                        <div className="form__title">
                            When you come to AAC for substance treatment services, we see
                            you as more than your addiction.
                        </div>
                        <div className="input__container">
                            <SelectInputStyles>
                                <SelectInput
                                    options={personaOptions}
                                    label="I am"
                                    fieldName="persona_choice"
                                    placeholder="Please select one"
                                    {...form}
                                />
                            </SelectInputStyles>
                            <SelectInputStyles>
                                <SelectInput
                                    options={personaCtas}
                                    label="I want to"
                                    fieldName="search_choice"
                                    placeholder="Please select one"
                                    {...form}
                                />
                            </SelectInputStyles>
                            <Button
                                type="submit"
                                disabled={!isDisabled}
                                style={{width: '100%'}}>
                                Next
                            </Button>
                        </div>
                    </div>
                </form>
            )}
            <style jsx>
                {`
                    .persona-assessment {
                        box-shadow: var(--box-shadow);
                        border-radius: 8px;
                    }
                    .back {
                        position: absolute;
                        color: var(--primary);
                        font-weight: bold;
                        display: grid;
                        grid-template-columns: 20px auto;
                        grid-gap: 8px;
                        align-items: center;
                        top: 4px;
                        left: 0;
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .header {
                        background: var(--primary-400);
                        color: #fff;
                        text-align: center;
                        font-weight: bold;
                        border-radius: 8px 8px 0 0;
                        padding: 16px;
                        font-size: 24px;
                        position: relative;
                    }
                    .container {
                        background: #fff;
                        padding: 40px;
                        border-radius: 0 0 8px 8px;
                        position: relative;
                    }
                    .cta__title {
                        margin: 0 auto 24px auto;
                        text-align: center;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 28px;
                        padding: 0 16px;
                    }
                    .form__title {
                        margin: 0 auto 24px auto;
                        text-align: center;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 28px;
                        max-width: 520px;
                    }
                    .input__container {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 24px;
                        max-width: 280px;
                        margin: 0 auto;
                    }
                `}
            </style>
        </div>
    );
};
export default PersonaAssessment;

const SelectInputStyles = styled.div`
    .options::-webkit-scrollbar {
        width: 4px;
    }
    .options::-webkit-scrollbar-track {
        background: var(--gray-200);
        width: 4px;
    }
    .options::-webkit-scrollbar-thumb {
        background: var(--gray-300);
        border-radius: 2em;
    }
`;
